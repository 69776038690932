import { render, staticRenderFns } from "./CreateUpdateSeries.vue?vue&type=template&id=28b9bb2f&"
import script from "./CreateUpdateSeries.vue?vue&type=script&lang=js&"
export * from "./CreateUpdateSeries.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../Users/kcger/AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports